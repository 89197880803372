import React, { ReactNode, useCallback } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core';
import toast from 'react-hot-toast';
import useLocales from '#/lib/hooks/useLocales';
import useLocalStorage from '#/lib/hooks/useLocalStorage';

interface DndProviderProps {
  moveFile: (vars: {
    file_id: number | string | null;
    folder_id: number | string | null;
  }) => Promise<void>;
  moveFolder: (vars: {
    folder_id: number | string | null;
    parent_id: number | null;
  }) => Promise<void>;
  onDragStart?: (event: DragStartEvent) => void;
  onDragEndRefresh?: () => void;
  children: ReactNode;
}

export const DndProvider: React.FC<DndProviderProps> = ({
  children,
  moveFile,
  moveFolder,
  onDragStart,
  onDragEndRefresh,
}) => {
  const [guestOn] = useLocalStorage('guest_on', false);
  const { translate } = useLocales();

  // Set up sensors with an activation constraint:
  // User must hold pointer down for 150ms before a drag starts.
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        tolerance: 2,
        delay: 100,
      },
    })
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over) return;

      const draggedId = active.id;
      const targetFolderId =
        over.id === null || over.id === '' ? null : Number(over.id);
      const draggedType = active.data.current?.type;

      if (
        draggedType === 'folder' &&
        draggedId.toString() === over.id.toString()
      ) {
        return;
      }

      if (!draggedType) return;

      if (draggedType === 'file') {
        await moveFile({
          file_id: draggedId,
          folder_id: targetFolderId,

          ...(guestOn && {
            invitation_id: guestOn,
          }),
        });
        toast.success(translate('toast_notifications.success.fileMove'));
      } else if (draggedType === 'folder') {
        await moveFolder({
          folder_id: draggedId,
          parent_id: targetFolderId,

          ...(guestOn && {
            invitation_id: guestOn,
          }),
        });
        toast.success(translate('toast_notifications.success.fileMove'));
      }

      if (onDragEndRefresh) {
        onDragEndRefresh();
      }
    },
    [moveFile, moveFolder, onDragEndRefresh]
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
    </DndContext>
  );
};
