import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  invalidateSuccessorQueries,
  useGetInvitationsList,
  useUpdateInvitation,
} from '#/lib/api/successor';
import useLocales from '#/lib/hooks/useLocales';
import { Successor } from '#/types/user';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  onClose: () => void;
};

export default function AddNewItem({ onClose }: Props) {
  const [selectedSuccessor, setSelectedSuccessor] = useState<string>();
  const [selectedAction, setSelectedAction] = useState<string>('view');

  const { translate } = useLocales();
  const navigate = useNavigate();

  const { data: successors } = useGetInvitationsList(undefined, 'droplist');

  const { mutateAsync: updateInvitationRights } =
    useUpdateInvitation(selectedSuccessor);

  const handleUpdateInvitationRights = async () => {
    try {
      const successor = successors?.find(
        (successor) => successor.unique_id === selectedSuccessor
      ) as Successor;

      await updateInvitationRights({
        ...successor,
        specific_service_access: [
          ...(successor?.specific_service_access || []),
          { service: 'tax', action: 'view', accessLevel: 'while-alive' },
        ],
      });
      invalidateSuccessorQueries.getInvitationsList();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            color: 'text.secondary',
          }}
        >
          {translate('global.userSettings.successors')}
        </InputLabel>
        <Select
          value={selectedSuccessor}
          onChange={(e) => setSelectedSuccessor(e.target.value)}
          label={translate('global.userSettings.successors')}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              p: 1,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
            onClick={() => {
              navigate('/dashboard/user/account', {
                state: {
                  activeTab: 'successors',
                },
              });
              onClose();
            }}
          >
            <Iconify icon="mdi:plus" />
            <Typography>{translate('global.addNewSuccessor')}</Typography>
          </Stack>
          {successors?.map((successor) => (
            <MenuItem key={successor.unique_id} value={successor.unique_id}>
              {successor.first_name} {successor.last_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            color: 'text.secondary',
          }}
        >
          {translate('global.access')}
        </InputLabel>
        <Select
          value={selectedAction}
          onChange={(e) => setSelectedAction(e.target.value)}
          label={translate('global.access')}
        >
          <MenuItem value="view">{translate('global.view')}</MenuItem>
          <MenuItem value="edit">
            <Typography>{translate('global.edit')}</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" onClick={onClose}>
          <Typography>{translate('global.cancel')}</Typography>
        </Button>
        <LoadingButton
          disabled={!selectedSuccessor || !selectedAction}
          variant="contained"
          onClick={handleUpdateInvitationRights}
        >
          <Typography>{translate('global.save')}</Typography>
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
