import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { TableProps } from '#/types/elements';
import FileTableRow from './table-row';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import { FileAPI, FilesAPI, FolderAPI } from '#/types/digitalAssets';
import {
  invalidateDigitalAssetsQueries,
  useCreateFolder,
  useDeleteFile,
  useDeleteFolder,
} from '#/lib/api/digitalAssets';
import Scrollbar from '#/components/shared/Scrollbar';
import FileManagerNewFolderDialog from '#/components/shared/file-manager/file-manager-new-folder-dialog';
import useLocales from '#/lib/hooks/useLocales';
import useBoolean from '#/lib/hooks/useBoolean';
import FileManagerPanel from '#/components/shared/file-manager/file-manager-panel';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import Iconify from '#/components/shared/ui/Iconify';
import TableSelectedAction from '#/components/shared/table/table-selected-action';
import FileManagerDetails from '#/components/shared/file-manager/file-manager-details';
import { useGetInvitationsList } from '#/lib/api/successor';
import useFileSystemPermissions from '#/lib/hooks/useFileSystemPermissions';

type Props = {
  table: TableProps;
  dataFiltered?: FilesAPI;
  openFolder: (id: string, name: string) => void;
  openFile: (filepath: string) => void;
  onOpenConfirm: VoidFunction;
  parentList: any[];
  notFound: boolean;
  service?: string;
  guestOn?: string | boolean;
  specificServiceAccess?: {
    service: string;
    action: string;
  };
};

export default function TableView({
  table,
  dataFiltered,
  openFolder,
  openFile,
  onOpenConfirm,
  parentList,
  notFound,
  service,
  guestOn = false,
  specificServiceAccess,
}: Props) {
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);
  const [folderName, setFolderName] = useState('');

  const TABLE_HEAD = [
    { id: '', width: 88 },
    { id: '', width: 88 },

    { id: 'name', label: 'global.fileSystemTableHead.name' },
    { id: 'size', label: 'global.fileSystemTableHead.size' },
    { id: 'type', label: 'global.fileSystemTableHead.type' },
    {
      ...(service === 'tax' && {
        id: 'document_type',
        label: 'global.documentType',
        width: 120,
      }),
      ...(service !== 'tax' && {
        id: 'modifiedAt',
        label: 'global.fileSystemTableHead.modifiedAt',
        width: 120,
      }),
    },
    {
      ...(service === 'tax' && {
        id: 'modifiedAt',
        label: 'global.fileSystemTableHead.modifiedAt',
        width: 120,
      }),
    },
  ];

  const { translate } = useLocales();
  const theme = useTheme();

  const { mutateAsync: createFolder, isLoading: creatingFolder } =
    useCreateFolder();
  const { data: sharingList } = useGetInvitationsList(undefined, 'tax');

  const { mutateAsync: onFolderDelete } = useDeleteFolder();
  const { mutateAsync: onFileDelete } = useDeleteFile();

  const { selected, onSelectRow, onSelectAllRows, dense } = table;

  const newFolder = useBoolean();
  const upload = useBoolean();
  const details = useBoolean();

  const permissions = useFileSystemPermissions({
    service,
    parentList,
    guestOn,
    specificServiceAccess,
  });

  const handleCollectDownloadUrls = (url: string) => {
    setDownloadUrls((prev) => [...prev, url]);
  };

  const handleDownloadFiles = useCallback(() => {
    if (!downloadUrls.length) {
      return;
    }

    downloadUrls.forEach((url) => {
      try {
        window.open(url, '_blank');
      } catch (error) {
        console.error('Failed to download file:', error);
      }
    });

    setDownloadUrls([]); // Clear URLs after download
  }, [downloadUrls]);

  const handleChangeFolderName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFolderName(event.target.value);
    },
    []
  );

  const handleFolderCreate = async () => {
    try {
      const parent = parentList[parentList.length - 1];
      await createFolder({
        ...(guestOn && { invitation_id: guestOn }),
        name: folderName,
        parent: parentList.length > 0 ? parent.id : null,
        ...(service && { service }),
      });
      newFolder.onFalse();
      invalidateDigitalAssetsQueries.getFiles();
      setFolderName('');
      toast.success(
        translate('toast_notifications.success.folderCreatedSuccessfully')
      );
    } catch (error) {
      toast.error(translate('toast_notifications.error.folderCreatedError'));
    }
  };

  const handleOpenFile = (row: any, file?: string) => {
    if (!file) openFolder(row?.id, row.name);
    else openFile(file);
  };

  const handleFileDelete = async (id: string) => {
    try {
      await onFileDelete({ id });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting file: ');
    }
  };

  const handleFolderDelete = async (id: string) => {
    try {
      await onFolderDelete({ id });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting folder: ');
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <FileManagerPanel
            title={translate('global.folder')}
            subTitle={`${dataFiltered?.folders?.length} ${translate(
              'global.folders'
            )}`}
            onOpen={newFolder.onTrue}
            hideButton={!permissions.canCreateFolder}
          />
          <FileManagerPanel
            title={translate('global.file')}
            subTitle={`${dataFiltered?.files?.length} ${translate(
              'global.files'
            )}`}
            onOpen={upload.onTrue}
            hideButton={!permissions.canUploadFiles}
          />
        </Stack>
        {permissions.canShare && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle1">
              {translate('global.shareWith')}
            </Typography>
            <AvatarGroup>
              {sharingList?.map((item, i) => (
                <Avatar
                  key={`${item.unique_id}-${service}-${i}`}
                  src={
                    item.avatar_url ||
                    '/assets/images/avatars/avatar-default.jpg'
                  }
                />
              ))}
            </AvatarGroup>
            <IconButton
              size="small"
              color="primary"
              onClick={details.onTrue}
              sx={{
                width: 24,
                height: 24,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              <Iconify icon="mingcute:add-line" />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <FileManagerDetails
        open={details.value}
        onClose={details.onFalse}
        sharingList={sharingList}
      />

      <FileManagerNewFolderDialog
        open={newFolder.value}
        onClose={newFolder.onFalse}
        title={String(translate('global.newFolder'))}
        onChangeFolderName={handleChangeFolderName}
        onCreate={handleFolderCreate}
        isLoading={creatingFolder}
      />
      <FileManagerNewFolderDialog
        open={upload.value}
        onClose={() => {
          upload.onFalse();
        }}
        parent={
          parentList.length > 0 ? parentList[parentList.length - 1].id : null
        }
        title={String(translate('global.uploadFiles'))}
        service={service}
      />
      <Box
        sx={{
          position: 'relative',
          m: theme.spacing(-2, -3, -3, -3),
        }}
      >
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={
            (dataFiltered?.folders?.length ?? 0) +
            (dataFiltered?.files?.length ?? 0)
          }
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              [
                ...(dataFiltered?.folders || []),
                ...(dataFiltered?.files || []),
              ].map((row) => row.id) as string[]
            )
          }
          action={
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip title={translate('global.download')}>
                <IconButton color="primary" onClick={handleDownloadFiles}>
                  <Iconify icon="mingcute:download-3-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title={translate('global.delete')}>
                <IconButton color="primary" onClick={onOpenConfirm}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            </Stack>
          }
          sx={{
            marginBottom: 3,
            top: 16,
            borderRadius: 1.5,
          }}
        />
        <TableContainer
          sx={{
            p: theme.spacing(0, 3, 3, 3),
          }}
        />
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 960, marginBottom: 3 }}>
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {dataFiltered?.folders?.map((row) => (
                <FileTableRow
                  key={`folder-${row.id}-${service}-${row.url} - ${row.name}`}
                  row={row as FolderAPI & FileAPI}
                  selected={selected.includes(String(row.id))}
                  onSelectRow={() => onSelectRow(String(row.id))}
                  onDeleteRow={() => handleFolderDelete(String(row.id))}
                  onOpen={() => handleOpenFile(row, row?.url ?? '')}
                  entityType="folder"
                  disableDrag={!permissions.canDrag}
                  hideInsertions={!permissions.canEdit}
                  service={service}
                  guestOn={guestOn}
                  specificServiceAccess={specificServiceAccess}
                  parentList={parentList}
                />
              ))}
              {dataFiltered?.files?.map((row) => (
                <FileTableRow
                  key={`file-${row.id}-${service}-${row.view_url} - ${row.filename}`}
                  row={row as FolderAPI & FileAPI}
                  selected={selected.includes(String(row.id))}
                  onSelectRow={() => {
                    onSelectRow(String(row.id));
                    handleCollectDownloadUrls(row.download_url);
                  }}
                  onDeleteRow={() => handleFileDelete(String(row.id))}
                  onOpen={() => handleOpenFile(row, row?.view_url)}
                  entityType="file"
                  disableDrag={!permissions.canDrag}
                  hideInsertions={!permissions.canEdit}
                  service={service}
                  guestOn={guestOn}
                  specificServiceAccess={specificServiceAccess}
                  parentList={parentList}
                />
              ))}
            </TableBody>
            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </Box>
    </>
  );
}
