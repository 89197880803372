import { Box, Link, SxProps, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useDroppable } from '@dnd-kit/core';
import { BreadcrumbsLinkProps } from './types';

type Props = {
  link: BreadcrumbsLinkProps;
  activeLast?: boolean;
  disabled: boolean;
  service?: string;
  style?: SxProps;
};

export default function BreadcrumbsLink({
  link,
  activeLast,
  disabled,
  service,
  style,
}: Props) {
  const { setNodeRef, isOver } = useDroppable({
    id: link.id || '',
    disabled: disabled || (link.id === null && service === 'tax'),
  });

  const styles: SxProps = {
    // typography: 'body2',
    alignItems: 'center',
    color: 'text.primary',
    display: 'inline-flex',
    borderRadius: 4,

    backgroundColor: isOver ? 'success.lighter' : 'transparent',

    ...(disabled &&
      !activeLast && {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'text.disabled',
      }),

    ...(link.action && {
      cursor: 'pointer',
      '&:hover': {
        color: 'primary.main',
        backgroundColor: 'action.hover',
      },
    }),

    ...style,
  };

  const renderContent = (
    <>
      {link.icon && (
        <Box
          component="span"
          sx={{
            mr: 1,
            display: 'inherit',
            '& svg': { width: 20, height: 20 },
          }}
        >
          {link.icon}
        </Box>
      )}

      <Typography variant="subtitle1">{link.name}</Typography>

      {/* {anyLinkOver && (
        <Box
          component="span"
          sx={{
            ml: 1,
            display: 'inherit',
            '& svg': {
              width: 20,
              height: 20,
              animation: `${waveAnimation} 1s ease-in-out infinite`,
            },
            color: 'success.main',
          }}
        >
          <Iconify icon="mingcute:transfer-line" />
        </Box>
      )} */}
    </>
  );

  if (link.href) {
    return (
      <Link ref={setNodeRef} component={RouterLink} to={link.href} sx={styles}>
        {renderContent}
      </Link>
    );
  }

  return (
    <Box ref={setNodeRef} onClick={link.action} sx={styles}>
      {renderContent}
    </Box>
  );
}
