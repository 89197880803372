export interface FileSystemPermissions {
  canEdit: boolean;
  canDrag: boolean;
  canCreateFolder: boolean;
  canUploadFiles: boolean;
  canShare: boolean;
  canDelete: boolean;
  canSelect: boolean;
}

export interface FileSystemProps {
  service?: string;
  parentList: any[];
  guestOn: string | boolean;
  specificServiceAccess?: {
    service: string;
    action: string;
  };
  currentItemName?: string;
}

export const PROTECTED_TAX_FOLDERS = [
  'All documents',
  'Final tax declaration',
  'Tax invoices',
] as const;

export const isProtectedTaxFolder = (name: string): boolean =>
  PROTECTED_TAX_FOLDERS.includes(
    name as (typeof PROTECTED_TAX_FOLDERS)[number]
  );
