import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TableCell,
  tableCellClasses,
  TableRow,
  tableRowClasses,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { Fragment, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useDoubleClick } from '#/lib/hooks/useDoubleClick';
import useBoolean from '#/lib/hooks/useBoolean';
import FileThumbnail from '#/components/shared/file-thumbnail';
import { fData } from '#/utils/formatNumber';
import { fDate, fTime } from '#/utils/formatTime';
import Iconify from '#/components/shared/ui/Iconify';
import usePopover from '#/lib/hooks/usePopover';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useLocales from '#/lib/hooks/useLocales';
import { FileAPI, FolderAPI } from '#/types/digitalAssets';
import IconButtonAnimate from '#/components/shared/ui/IconButtonAnimate';
import ConfirmDialog from '#/components/shared/confirm-dialog';
import FileManagerNewFolderDialog from '#/components/shared/file-manager/file-manager-new-folder-dialog';
import {
  invalidateDigitalAssetsQueries,
  useRenameFile,
  useRenameFolder,
  useToggleFavoriteFile,
  useToggleFavoriteFolder,
  useUpdateDocumentType,
} from '#/lib/api/digitalAssets';
import useFileSystemPermissions from '#/lib/hooks/useFileSystemPermissions';
import { TAG_CHOICES } from '../../TaxFolderClient/constants';

interface Row extends FolderAPI, FileAPI {}

const FOLDER_NAME_MAP = {
  'All documents': 'global.allDocuments',
  'Final tax declaration': 'global.finalTaxDeclaration',
  'Proof of income': 'global.proofOfIncome',
  'Proof of assets': 'global.proofOfAssets',
  'Deductible expenses': 'global.deductibleExpenses',
  'Real estate and properties': 'global.realEstateAndProperties',
  'Other documents': 'global.otherDocuments',
  'Tax invoices': 'global.taxInvoices',
  Tax: 'global.tax',
};

type Props = {
  row: Row;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onOpen?: VoidFunction;
  entityType: 'folder' | 'file';
  disableDrag?: boolean;
  hideInsertions?: boolean;
  service?: string;
  guestOn?: string | boolean;
  specificServiceAccess?: {
    service: string;
    action: string;
  };
  parentList: any[];
};

export default function FileTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onOpen,
  entityType,
  disableDrag,
  hideInsertions,
  service,
  guestOn = false,
  specificServiceAccess,
  parentList,
}: Props) {
  const [editDocumentType, setEditDocumentType] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>(row.document_type);
  const [newName, setNewName] = useState(
    row.name || row.filename.substring(0, row.filename.lastIndexOf('.'))
  );

  const theme = useTheme();

  const {
    name,
    filename,
    size,
    content_type,
    uploaded_at,
    document_type,
    classification_confidence,
    update_by,
  } = row;

  const { mutateAsync: updateFolder } = useRenameFolder(row.id);

  const { mutateAsync: updateFile, isLoading: isUpdatingName } = useRenameFile(
    row.id
  );
  const { mutateAsync: updateDocumentType, isLoading: isUpdatingType } =
    useUpdateDocumentType(row.id);

  const { mutateAsync: toggleFavoriteFolder } = useToggleFavoriteFolder(row.id);
  const { mutateAsync: toggleFavoriteFile } = useToggleFavoriteFile(row.id);

  const handleEditDocumentType = () => setEditDocumentType(true);

  const handleFavorite = async () => {
    try {
      if (entityType === 'folder') {
        // @ts-ignore
        await toggleFavoriteFolder({ name: row.name });
        invalidateDigitalAssetsQueries.getFiles();
      }
      if (entityType === 'file') {
        // @ts-ignore
        await toggleFavoriteFile({ name: row.filename });
        invalidateDigitalAssetsQueries.getFiles();
      }
    } catch (error) {
      console.error('Error updating favorite: ');
    }
  };

  const permissions = useFileSystemPermissions({
    service,
    parentList,
    guestOn,
    specificServiceAccess,
    currentItemName: name || filename,
  });

  const {
    attributes,
    listeners,
    setNodeRef: setDraggableRef,
    transform,
    isDragging,
  } = useDraggable({
    id: row.id,
    data: { type: entityType, row },
    disabled: !(!hideInsertions && permissions.canEdit),
  });

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: row.id,
    disabled: entityType !== 'folder',
  });

  // Combine refs for draggable and droppable
  const setNodeRef = (node: HTMLElement | null) => {
    setDraggableRef(node);
    setDroppableRef(node);
  };

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    ...(service === 'tax' &&
      update_by === 'not_set' &&
      !document_type && {
        color: 'action.disabled',
        opacity: 0.5,
      }),
    transition: 'transform 200ms ease',
    backgroundColor:
      isOver && entityType === 'folder'
        ? theme.palette.action.hover
        : 'inherit',
  };

  const { translate } = useLocales();

  const popover = usePopover();
  const confirm = useBoolean();
  const editFolder = useBoolean();
  const editFile = useBoolean();
  const favorite = useBoolean(row.starred);

  const handleClick = useDoubleClick({
    click: () => {
      onOpen?.();
    },
    doubleClick: () => console.info('DOUBLE CLICK'),
  });

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewName(event.target.value);
    },
    []
  );

  const handleDocumentTypeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setDocumentType(event.target.value);
    },
    []
  );

  const handleFolderRename = async (name: string) => {
    try {
      await updateFolder({ name });
      invalidateDigitalAssetsQueries.getFiles();
      editFolder.onFalse();
    } catch (error) {
      console.error('Error updating folder: ');
    }
  };

  const handleFileRename = async (name: string) => {
    try {
      if (documentType !== row.document_type) {
        await updateDocumentType({
          document_type: documentType,
          updated_by: 'manually',
        });
      }
      if (name !== row.filename.substring(0, row.filename.lastIndexOf('.'))) {
        await updateFile({ name });
      }

      toast.success(translate('toast_notifications.success.saving'));
      setEditDocumentType(false);
      invalidateDigitalAssetsQueries.getFiles();
      editFile.onFalse();
    } catch (error) {
      console.error('Error updating folder: ');
    }
  };

  const folderName = FOLDER_NAME_MAP[name as keyof typeof FOLDER_NAME_MAP];

  const showTooltip =
    (classification_confidence < 0.6 && update_by === 'webhook') ||
    (!document_type && (update_by === 'not_set' || update_by === 'failed'));

  const notSetTooltip = translate('global.pending');
  const failedTooltip = translate('global.docClassificationWarning');
  const webhookTooltip = translate('global.docClassificationWarning');

  const getTooltip = (update_by: string) => {
    switch (update_by) {
      case 'webhook':
        return webhookTooltip;
      case 'not_set':
        return notSetTooltip;
      case 'failed':
        return failedTooltip;
      default:
        return '';
    }
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      sx={{
        borderRadius: 2,
        [`&.${tableRowClasses.selected}, &:hover`]: {
          backgroundColor: 'background.paper',
          boxShadow: theme.customShadows.z20,
          transition: theme.transitions.create(
            ['background-color', 'box-shadow'],
            {
              duration: theme.transitions.duration.shortest,
            }
          ),
          '&:hover': {
            backgroundColor: 'background.paper',
            boxShadow: theme.customShadows.z20,
          },
        },
        [`& .${tableCellClasses.root}`]: {},
      }}
    >
      <TableCell padding="checkbox">
        {!hideInsertions && permissions.canSelect && (
          <Checkbox
            checked={selected}
            onDoubleClick={() => console.info('ON DOUBLE CLICK')}
            onClick={onSelectRow}
          />
        )}
      </TableCell>

      <TableCell padding="checkbox">
        {!hideInsertions && permissions.canEdit && (
          <IconButtonAnimate
            sx={{
              cursor: 'grab',
            }}
            {...listeners}
            {...attributes}
          >
            <Iconify icon="mdi:drag" />
          </IconButtonAnimate>
        )}
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
            width: 'fit-content',
          }}
        >
          <FileThumbnail
            file={content_type?.split('/')[1] ?? 'folder'}
            sx={{ width: 36, height: 36 }}
          />

          <Typography
            noWrap
            variant="inherit"
            sx={{
              maxWidth: 360,
            }}
          >
            {folderName ? translate(folderName) : name || filename}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{fData(size)}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {content_type?.split('/')[1].toUpperCase() ||
          translate('global.folder')}
      </TableCell>

      {service === 'tax' &&
        (editDocumentType ? (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Autocomplete
                options={TAG_CHOICES}
                value={TAG_CHOICES.find((tag) => tag.value === documentType)}
                getOptionLabel={(option) => translate(option.label)}
                size="small"
                onChange={(_event, value) => {
                  setDocumentType(value?.value || '');
                }}
                sx={{
                  width: 200,
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    value={documentType}
                    {...params}
                    name="document_type"
                  />
                )}
              />

              <Iconify
                icon="eva:save-outline"
                onClick={() => handleFileRename(newName)}
                width={24}
                height={24}
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                }}
              />
            </Stack>
          </TableCell>
        ) : (
          <TableCell
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            <Stack spacing={1}>
              {entityType === 'file' ? (
                <Stack spacing={2}>
                  <Tooltip
                    title={getTooltip(update_by)}
                    disableHoverListener={!showTooltip}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {classification_confidence <= 0.6 &&
                        update_by === 'webhook' && (
                          <Iconify
                            icon="fluent:warning-32-filled"
                            color="error.main"
                            width={24}
                          />
                        )}
                      {document_type && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Iconify
                            icon="solar:pen-bold"
                            width={16}
                            height={16}
                            onClick={handleEditDocumentType}
                            sx={{
                              cursor: 'pointer',
                            }}
                          />
                          <Typography variant="body2">
                            {translate(
                              `global.ppDocumentChoices.${document_type}`
                            )}
                          </Typography>
                        </Stack>
                      )}
                      {(update_by === 'not_set' || update_by === 'failed') &&
                        !document_type && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                            sx={{ width: '100%' }}
                          >
                            {update_by !== 'not_set' && (
                              <Iconify
                                icon="solar:pen-bold"
                                width={16}
                                height={16}
                                onClick={handleEditDocumentType}
                                sx={{
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                            <Box
                              sx={{
                                borderRadius: 50,
                                width: 14,
                                height: 14,
                                backgroundColor:
                                  update_by === 'not_set'
                                    ? 'action.disabled'
                                    : 'error.light',
                              }}
                            />
                          </Stack>
                        )}
                      {!document_type && update_by === 'webhook' && 'N/A'}
                    </Stack>
                  </Tooltip>
                </Stack>
              ) : (
                'N/A'
              )}
            </Stack>
          </TableCell>
        ))}

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <ListItemText
          primary={fDate(uploaded_at)}
          secondary={fTime(uploaded_at, undefined, true)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      <TableCell
        align="right"
        sx={{
          px: 1,
          whiteSpace: 'nowrap',
        }}
      >
        {!hideInsertions && permissions.canEdit && (
          <>
            <Checkbox
              color="warning"
              icon={<Iconify icon="eva:star-outline" />}
              checkedIcon={<Iconify icon="eva:star-fill" />}
              checked={row.starred || favorite.value}
              onChange={() => {
                handleFavorite();
                favorite.onToggle();
              }}
              sx={{ p: 0.75 }}
            />

            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </>
        )}
      </TableCell>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160 }}
      >
        {permissions.canEdit && (
          <MenuItem
            onClick={() => {
              popover.onClose();
              if (entityType === 'folder') {
                editFolder.onTrue();
              }
              if (entityType === 'file') {
                editFile.onTrue();
              }
            }}
          >
            <Iconify icon="solar:pen-bold" />
            {String(translate('global.edit'))}
          </MenuItem>
        )}
        {permissions.canDelete && (
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            {translate('global.delete')}
          </MenuItem>
        )}
      </CustomPopover>
      {entityType === 'folder' && (
        <FileManagerNewFolderDialog
          open={editFolder.value}
          onClose={editFolder.onFalse}
          title={String(translate('global.editFolder'))}
          onUpdate={() => handleFolderRename(newName)}
          onChangeFolderName={handleNameChange}
          folderName={newName}
        />
      )}
      {entityType === 'file' && (
        <FileManagerNewFolderDialog
          open={editFile.value}
          onClose={editFile.onFalse}
          title={String(translate('global.editFile'))}
          onUpdate={() => handleFileRename(newName)}
          onChangeFolderName={handleNameChange}
          folderName={newName}
          onChangeDocumentType={handleDocumentTypeChange}
          documentType={documentType}
          service={service}
          isLoading={isUpdatingName || isUpdatingType}
        />
      )}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={String(translate('global.deleteConfirmation.title'))}
        content={String(
          translate('global.deleteConfirmation.question', {
            item: folderName ? translate(folderName) : name || filename,
          })
        )}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              confirm.onFalse();
            }}
          >
            <Typography>{String(translate('global.delete'))}</Typography>
          </Button>
        }
      />
    </TableRow>
  );
}
