import { useState, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { Upload } from '../upload';
import Iconify from '../ui/Iconify';
import {
  invalidateDigitalAssetsQueries,
  useUploadFile,
} from '#/lib/api/digitalAssets';
import useLocales from '#/lib/hooks/useLocales';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import { TAG_CHOICES } from '#/components/pages/TaxFolderClient/constants';

interface Props extends DialogProps {
  title?: string;
  //
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  //
  folderName?: string;
  documentType?: string;
  onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDocumentType?: (event: SelectChangeEvent<string>) => void;
  //
  open: boolean;
  onClose: VoidFunction;
  parent?: string | number | null;
  isLoading?: boolean;
  inputLabel?: string;
  service?: string;
}

export default function FileManagerNewFolderDialog({
  title = 'Upload Files',
  open,
  onClose,
  //
  onCreate,
  onUpdate,
  //
  folderName,
  documentType,
  onChangeFolderName,
  onChangeDocumentType,
  parent,
  inputLabel,
  isLoading,
  ...other
}: Props) {
  const [files, setFiles] = useState<(File | string)[]>([]);
  const [guestOn] = useLocalStorage('guest_on', '');

  const { translate } = useLocales();
  const { mutateAsync: uploadFile, isLoading: uploadingFiles } =
    useUploadFile();

  const handleFileUpload = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
      if (guestOn) {
        formData.append('invitation_id', guestOn);
      }
      if (parent) {
        formData.append('folder', parent.toString());
      }
      if (other?.service) {
        formData.append('service', other?.service);
      }
    });

    try {
      await uploadFile(formData);
      invalidateDigitalAssetsQueries.getFiles();
      toast.success(translate('toast_notifications.success.fileUpload'));
      onClose();
    } catch (error) {
      onClose();
      toast.error(translate('toast_notifications.error.fileUpload'));
      console.error('Error uploading file: ');
    }
  };

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {' '}
        {title}{' '}
      </DialogTitle>
      {other?.service && !parent && !onUpdate && (
        <Stack sx={{ px: 3 }}>
          <Typography variant="body2">
            {translate('global.uploadingToWarning')}{' '}
            {translate('global.allDocuments')}
          </Typography>
        </Stack>
      )}

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {onCreate || onUpdate ? (
          <>
            <TextField
              fullWidth
              label={inputLabel || translate('global.folderName')}
              value={folderName}
              onChange={onChangeFolderName}
              sx={{ mb: 3 }}
            />
            {other?.service && (
              <FormControl fullWidth>
                <InputLabel>{translate('global.documentType')}</InputLabel>
                <Select
                  fullWidth
                  label={translate('global.documentType')}
                  placeholder={translate('global.documentType')}
                  value={documentType}
                  onChange={onChangeDocumentType}
                >
                  {TAG_CHOICES.map((tag) => (
                    <MenuItem key={tag.value} value={tag.value}>
                      {translate(tag.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        ) : (
          <Upload
            multiple
            files={files}
            onDrop={handleDrop}
            onRemove={handleRemoveFile}
          />
        )}
      </DialogContent>

      <DialogActions>
        {!onCreate && !onUpdate && (
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
            onClick={handleFileUpload}
          >
            <Typography>
              {uploadingFiles
                ? translate('global.uploading')
                : translate('global.upload')}
            </Typography>
          </Button>
        )}

        {!!files.length && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleRemoveAllFiles}
          >
            <Typography>{translate('global.removeAll')}</Typography>
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            <LoadingButton
              variant="contained"
              loading={isLoading || uploadingFiles}
              onClick={onCreate || onUpdate}
            >
              <Typography>
                {onUpdate
                  ? translate('global.save')
                  : translate('global.create')}
              </Typography>
            </LoadingButton>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}
