export const TAG_CHOICES = [
  {
    value: 'general_invoice',
    label: 'global.ppDocumentChoices.general_invoice',
  },
  {
    value: 'alimony_confirmation',
    label: 'global.ppDocumentChoices.alimony_confirmation',
  },
  {
    value: 'third_pillar_confirmation',
    label: 'global.ppDocumentChoices.third_pillar_confirmation',
  },
  {
    value: 'real_estate_valuation',
    label: 'global.ppDocumentChoices.real_estate_valuation',
  },
  {
    value: 'balance_sheet',
    label: 'global.ppDocumentChoices.balance_sheet',
  },
  {
    value: 'income_statement',
    label: 'global.ppDocumentChoices.income_statement',
  },
  {
    value: 'salary_statement',
    label: 'global.ppDocumentChoices.salary_statement',
  },
  {
    value: 'mortgage_contract',
    label: 'global.ppDocumentChoices.mortgage_contract',
  },
  {
    value: 'yearly_salary_statement',
    label: 'global.ppDocumentChoices.yearly_salary_statement',
  },
  {
    value: 'account_statement',
    label: 'global.ppDocumentChoices.account_statement',
  },
  {
    value: 'monthly_salary_statement',
    label: 'global.ppDocumentChoices.monthly_salary_statement',
  },
  {
    value: 'rental_income',
    label: 'global.ppDocumentChoices.rental_income',
  },
  {
    value: 'pension_certificate',
    label: 'global.ppDocumentChoices.pension_certificate',
  },
  {
    value: 'pension_fund_purchase',
    label: 'global.ppDocumentChoices.pension_fund_purchase',
  },
  {
    value: 'pension_statement',
    label: 'global.ppDocumentChoices.pension_statement',
  },
  {
    value: 'receipt_childcare',
    label: 'global.ppDocumentChoices.receipt_childcare',
  },
  {
    value: 'receipt_education',
    label: 'global.ppDocumentChoices.receipt_education',
  },
  {
    value: 'receipt_travel_costs',
    label: 'global.ppDocumentChoices.receipt_travel_costs',
  },
  {
    value: 'renovation_list',
    label: 'global.ppDocumentChoices.renovation_list',
  },
  {
    value: 'health_insurance_invoice',
    label: 'global.ppDocumentChoices.health_insurance_invoice',
  },
  {
    value: 'debt_certificate',
    label: 'global.ppDocumentChoices.debt_certificate',
  },
  {
    value: 'confirmation_donation',
    label: 'global.ppDocumentChoices.confirmation_donation',
  },
  {
    value: 'tax_certificate',
    label: 'global.ppDocumentChoices.tax_certificate',
  },
  {
    value: 'tax_declaration',
    label: 'global.ppDocumentChoices.tax_declaration',
  },
  {
    value: 'tax_invoice',
    label: 'global.ppDocumentChoices.tax_invoice',
  },
  {
    value: 'utilities_statement',
    label: 'global.ppDocumentChoices.utilities_statement',
  },
  {
    value: 'insurance_policy',
    label: 'global.ppDocumentChoices.insurance_policy',
  },
  {
    value: 'securities_trading',
    label: 'global.ppDocumentChoices.securities_trading',
  },
  {
    value: 'interest_capital_statement',
    label: 'global.ppDocumentChoices.interest_capital_statement',
  },
];
