import { useMemo } from 'react';
import {
  FileSystemPermissions,
  FileSystemProps,
  isProtectedTaxFolder,
} from '#/types/permissions';

export const useFileSystemPermissions = ({
  service,
  parentList,
  guestOn,
  specificServiceAccess,
  currentItemName,
}: FileSystemProps): FileSystemPermissions =>
  useMemo(() => {
    const isTaxFolder = service === 'tax';
    const editDisabled = guestOn && specificServiceAccess?.action !== 'edit';
    const isRoot = parentList.length === 0;

    // Base permissions
    const canEdit = !editDisabled;

    // Special case for tax service
    const isTaxRoot = isTaxFolder && isRoot;

    // Only check if current item is protected, not its parents
    const isCurrentItemProtected =
      isTaxFolder && currentItemName && isProtectedTaxFolder(currentItemName);
    const isProtected = isCurrentItemProtected;

    // Enhanced security rules
    const canDrag = canEdit && !(isTaxFolder && parentList.length < 2);
    const canCreateFolder = !(isTaxFolder && (editDisabled || isRoot));
    const canUploadFiles = !editDisabled;
    const canShare = !editDisabled && isRoot && isTaxRoot;

    // New security rules
    const canDelete = canEdit && !isTaxRoot && !isProtected;
    const canSelect = !isTaxRoot && !isProtected;

    return {
      canEdit: canEdit && !isTaxRoot && !isProtected,
      canDrag,
      canCreateFolder,
      canUploadFiles,
      canShare,
      canDelete,
      canSelect,
    };
  }, [service, parentList, guestOn, specificServiceAccess, currentItemName]);

export default useFileSystemPermissions;
