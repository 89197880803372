import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Scrollbar from '../Scrollbar';
import { FileAPI, FolderAPI } from '#/types/digitalAssets';
import useLocales from '#/lib/hooks/useLocales';
import Iconify from '../ui/Iconify';
import useBoolean from '#/lib/hooks/useBoolean';
import FileManagerInvitedItem from './file-manager-invited-item';
import AddNewItem from '#/components/pages/TaxFolderClient/add-new-item';
import { Invitation } from '#/types/user';

type Props = DrawerProps & {
  sharingList?: Invitation['invitations'];
  item?: FileAPI | FolderAPI;
  onClose: VoidFunction;
};

export default function FileManagerDetails({
  sharingList,
  item,
  onClose,
  open,
  ...other
}: Props) {
  const { translate } = useLocales();

  const share = useBoolean();

  const renderShared = (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 2.5 }}
      >
        {share.value ? (
          <AddNewItem onClose={share.onFalse} />
        ) : (
          <IconButton
            size="small"
            color="primary"
            onClick={share.onTrue}
            sx={{
              width: 24,
              height: 24,
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
          >
            <Iconify icon="mingcute:add-line" />
          </IconButton>
        )}
      </Stack>
      {/* {hasShared && ( */}
      <Box sx={{ pl: 2.5, pr: 1 }}>
        {sharingList?.map((person) => (
          <FileManagerInvitedItem key={person.unique_id} person={person} />
        ))}
      </Box>
      {/* )} */}
    </>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      slotProps={{
        backdrop: { invisible: true },
      }}
      PaperProps={{
        sx: { width: 320 },
      }}
      {...other}
    >
      <Scrollbar sx={{ height: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2.5, py: 1 }}
        >
          <Typography variant="h6">{translate('global.shareWith')}</Typography>
          <IconButton onClick={onClose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>
        <Stack sx={{ px: 2.5 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {translate('global.shareWithDscp')}
          </Typography>
        </Stack>
        {renderShared}
      </Scrollbar>
    </Drawer>
  );
}
